import * as React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import Box from '@mui/material/Box';

const PreviewCompatibleImage = ({
	imageInfo,
	position = 'absolute',
	widthOuter,
	widthInner = '100%',
	height = '100%',
	top = 0,
	left = 0,
}) => {
	const imageStyle = {
		// borderRadius: '5px',
	};

	const { alt = '', childImageSharp, image } = imageInfo;

	if (!!image && !!image.childImageSharp) {
		return (
			<Box
				sx={{
					width: widthOuter,
					'& .gatsby-image-wrapper': {
						position: position,
						top: top,
						left: left,
						width: widthInner,
						height: height,
					},
				}}
			>
				<GatsbyImage
					image={image.childImageSharp.gatsbyImageData}
					style={imageStyle}
					alt={alt}
				/>
			</Box>
		);
	} else if (!!childImageSharp) {
		return (
			<Box
				sx={{
					'& .gatsby-image-wrapper': {
						position: position,
						top: top,
						left: left,
						width: widthInner,
						height: height,
					},
				}}
			>
				<GatsbyImage
					image={childImageSharp.gatsbyImageData}
					style={imageStyle}
					alt={alt}
				/>
			</Box>
		);
		// for Netlify CMS
	} else if (image) {
		return (
			<Box
				sx={{
					position: position,
					top: top,
					left: left,
					width: widthInner,
					height: height,
				}}
			>
				<img style={{ imageStyle }} src={image} alt={alt} />
			</Box>
		);
	} else {
		return null;
	}
};

PreviewCompatibleImage.propTypes = {
	imageInfo: PropTypes.shape({
		alt: PropTypes.string,
		childImageSharp: PropTypes.object,
		image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
		style: PropTypes.object,
	}).isRequired,
};

export default PreviewCompatibleImage;
